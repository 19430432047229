<script setup lang="ts">
import type { CollapseModel } from '~/models/CollapseModel'
import delve from 'dlv'
import {setFaqSchema} from "~/utils/setSchema";

const props = defineProps({
  faqs: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: false,
  },
  subtitle: {
    type: String,
    required: false,
  },
  employee: {
    type: Object,
    required: false,
  },
  ctaText: {
    type: String,
    required: false,
  },
  ctaPath: {
    type: String,
    required: false,
  },
  displayBtn: {
    type: Boolean,
    default: true,
  },
});

setFaqSchema(props.faqs)

const accordionElements = props.faqs?.map(({attributes: item}: Object) => {
    return <CollapseModel> {
      title: item.question,
      description: item.answer
    }
  }
)
</script>

<template>
  <div
    v-if="'undefined' !== typeof accordionElements"
    class="faqs-block-container"
  >
    <div class="faqs-block bg-sand-0">
      <UiElementsWaveTitle>
        <h2>{{title || $t('faq.title')}}</h2>
      </UiElementsWaveTitle>
      <h3 class="like-h6 title--secondary title--italic">{{subtitle || $t('faq.subtitle')}}</h3>
      <UiElementsAccordion :elements="accordionElements" />
    </div>
    <div class="faqs-block-more">
      <div class="faqs-block-employee" v-if="employee">
        <NuxtImg
          v-if="delve(employee, 'data.attributes.picture.data.attributes.url')"
          loading="lazy"
          format="webp"
          width="70"
          :src="getStrapiMedia(delve(employee, 'data.attributes.picture.data.attributes.url'))"
          :alt="delve(employee, 'data.attributes.name')"
        />
        <div>
          <div class="name">{{delve(employee, 'data.attributes.name')}}</div>
          <div>{{delve(employee, 'data.attributes.label')}}</div>
        </div>
      </div>
      <UiElementsButton
        v-if="displayBtn"
        color-style="outline"
        :to="{ name: ctaPath ? ctaPath : 'faq' }">
        <UiIconsMessage />
        {{ ctaText ? ctaText : $t('faq.cta') }}
      </UiElementsButton>
    </div>
  </div>
</template>

<style lang="scss">
  .faqs-block-container {
    background-color: var(--color-sand-0);
    font-size: 1rem;
    padding: 2.5em 1em;
    text-align: center;

    .wave-title {
      h2 {
        margin: 0;
        font-size: 1.5em;

        @media (min-width: map-get($grid-breakpoints, md)) {
          font-size: 2rem;
        }
      }
    }

    h3 {
      margin-top: 2.5em;
      font-size: 1rem;

      @media (min-width: map-get($grid-breakpoints, md)) {
        font-size: 1.25rem;
      }
    }

    .faqs-block {
      margin-bottom: 2em
    }

    .faqs-block-employee {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      gap: 1em;
      font-weight: 400;

      @media (min-width: map-get($grid-breakpoints, md)) {
        flex-direction: row;
        text-align: left;
      }

      .name {
        font-family: var(--font-secondary);
        font-weight: 600;
        font-size: 1.5em
      }
    }

    .faqs-block-more {
      max-width: 850px;
      gap: 2em;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media (min-width: map-get($grid-breakpoints, md)) {
        flex-direction: row;
      }
    }
  }
</style>
